var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    { staticClass: "content-center min-vh-100" },
    [
      _c(
        "CRow",
        {
          staticClass:
            "justify-content-center col-sm-12 col-md-12 col-lg-12 col-xl-8"
        },
        [
          _c(
            "CCol",
            [
              _c(
                "CCardGroup",
                [
                  _c(
                    "CCard",
                    { staticClass: "p-4 login-card" },
                    [
                      _c(
                        "CCardBody",
                        [
                          _c(
                            "CRow",
                            [
                              _c(
                                "CCol",
                                { attrs: { md: "12" } },
                                [
                                  _c("h1", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value: "pages.auth.Login.title",
                                        expression: "'pages.auth.Login.title'"
                                      }
                                    ]
                                  }),
                                  _c("p", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value: "pages.auth.Login.subtitle",
                                        expression:
                                          "'pages.auth.Login.subtitle'"
                                      }
                                    ],
                                    staticClass: "text-muted"
                                  }),
                                  _c(
                                    "CForm",
                                    {
                                      on: {
                                        submit: function($event) {
                                          $event.preventDefault()
                                          return _vm.handleLogin($event)
                                        }
                                      }
                                    },
                                    [
                                      _vm.loading
                                        ? _c("CElementCover", {
                                            attrs: { opacity: 0.1 }
                                          })
                                        : _vm._e(),
                                      _c("div", { staticClass: "form-group" }, [
                                        _vm.message
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "alert alert-danger",
                                                attrs: { role: "alert" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.message) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]),
                                      _c("ValidatedInput", {
                                        attrs: {
                                          translationKey: "login.email",
                                          field: _vm.$v.form.username,
                                          "data-cy": "email",
                                          autocomplete: "email",
                                          value: _vm.$v.form.username.$model
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.$v.form.username,
                                              "$model",
                                              $event
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "prepend-content",
                                            fn: function() {
                                              return [
                                                _c("CIcon", {
                                                  attrs: { name: "cil-at" }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      }),
                                      _c("ValidatedInput", {
                                        attrs: {
                                          translationKey: "login.password",
                                          field: _vm.$v.form.password,
                                          value: _vm.$v.form.password.$model,
                                          autocomplete: "new-password",
                                          type: "password",
                                          "data-cy": "password"
                                        },
                                        on: {
                                          "update:value": function($event) {
                                            return _vm.$set(
                                              _vm.$v.form.password,
                                              "$model",
                                              $event
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "prepend-content",
                                            fn: function() {
                                              return [
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-lock-locked"
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      }),
                                      _c("ValidatedCheckbox", {
                                        staticClass: "inline-checkbox",
                                        attrs: {
                                          translationKey: "login.rememberMe",
                                          field: _vm.$v.form.rememberMe,
                                          checked:
                                            _vm.$v.form.rememberMe.$model,
                                          "data-cy": "rememberMe"
                                        },
                                        on: {
                                          "update:checked": function($event) {
                                            return _vm.$set(
                                              _vm.$v.form.rememberMe,
                                              "$model",
                                              $event
                                            )
                                          }
                                        }
                                      }),
                                      _c("CButton", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value:
                                              "pages.auth.Login.loginButton",
                                            expression:
                                              "'pages.auth.Login.loginButton'"
                                          }
                                        ],
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.loading,
                                          type: "submit",
                                          "data-cy": "login"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "text-right mt-3",
                                  attrs: { col: "12" }
                                },
                                [
                                  _c("router-link", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value:
                                          "pages.auth.Login.forgotPassword",
                                        expression:
                                          "'pages.auth.Login.forgotPassword'"
                                      }
                                    ],
                                    attrs: {
                                      to: { name: "ForgotPassword" },
                                      "data-cy": "forgot-password"
                                    }
                                  }),
                                  _vm._v(" | "),
                                  _c("router-link", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value:
                                          "pages.auth.Login.resendConfirmation",
                                        expression:
                                          "'pages.auth.Login.resendConfirmation'"
                                      }
                                    ],
                                    attrs: {
                                      to: { name: "ResendConfirmation" },
                                      "data-cy": "resend-confirmation"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CCard",
                    {
                      staticClass:
                        "text-center register-card d-sm-down-none text-white",
                      staticStyle: { "background-color": "#303030" }
                    },
                    [
                      _c(
                        "CCardBody",
                        [
                          _c("h2", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value: "pages.auth.Login.titleRegister",
                                expression: "'pages.auth.Login.titleRegister'"
                              }
                            ]
                          }),
                          _c("router-link", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value: "pages.auth.Login.registerAsGuest",
                                expression: "'pages.auth.Login.registerAsGuest'"
                              }
                            ],
                            staticClass: "btn mt-3 btn-outline-secondary",
                            attrs: { to: { name: "RegisterCustomer" } }
                          }),
                          _c("br"),
                          _c("router-link", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value: "pages.auth.Login.registerAsHost",
                                expression: "'pages.auth.Login.registerAsHost'"
                              }
                            ],
                            staticClass: "btn mt-1 btn-outline-secondary",
                            attrs: { to: { name: "RegisterOwner" } }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }